import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import { jsonQueryFromResultsSelector } from '../../../../../../selectors/getJsonQuery';

import { TgbNativedescGeneral, TgbNativedescGeneralProps } from './TgbNativedescGeneral';
import { useNewbuidingInfoForTgb } from './internal/hooks/useNewbuidingInfoForTgb';
import { jsonRegionIdToTransportType } from './internal/utils/jsonRegionIdToTransportType';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const {
    annotation,
    image,
    url,
    metroDistance,
    metroColor,
    metro,
    description,
    title,
    location,
    button,
    label,
    logoImageUrl,
    builderName,
  } = context;

  const newbuidingInfoForTgb = useNewbuidingInfoForTgb(context);

  const jsonQuery = useSelector(jsonQueryFromResultsSelector);

  const tgbNativedescGeneralProps = ((): TgbNativedescGeneralProps => {
    if (newbuidingInfoForTgb) {
      return {
        logoImageUrl: newbuidingInfoForTgb.publisher.logo.url,
        url,
        transports: newbuidingInfoForTgb.transports,
        label,
        button,
        image,
        annotation,
        builderName: newbuidingInfoForTgb.publisher.name,
        title,
        address: newbuidingInfoForTgb.address,
        description,
      };
    }

    const transports: TgbNativedescGeneralProps['transports'] = [];

    if (metro) {
      const iconType = jsonRegionIdToTransportType(jsonQuery);

      transports.push({
        iconColor: metroColor,
        iconType,
        id: 1,
        name: metro,
        remoteness: metroDistance,
      });
    }

    return {
      logoImageUrl,
      url,
      transports,
      label,
      button,
      image,
      annotation,
      builderName,
      title,
      address: location,
      description,
    };
  })();

  return <TgbNativedescGeneral {...tgbNativedescGeneralProps} />;
};
