import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { IAppState } from '../../../../../../../../common/state';
import { selectResultPuids } from '../../../../../../../../selectors/results/selectResultPuids';
import { PParams } from '../../types';

export function useAdfoxParameters(pParams: PParams): Record<string, string> {
  const puids = useSelector<IAppState, Record<string, string>>(selectResultPuids, shallowEqual);

  return useMemo<Record<string, string>>(() => ({ ...pParams, ...puids }), [pParams, puids]);
}
