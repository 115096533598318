import { IAppState } from '../../common/state';
import { getRailwayAndHighwayPuids } from '../../serp/components/offers/helpers';
import { jsonQueryFromResultsSelector } from '../getJsonQuery';

export function selectResultPuids(state: IAppState): Record<string, string> {
  const jsonQuery = jsonQueryFromResultsSelector(state);

  const puids: Record<string, string> = {
    ...getRailwayAndHighwayPuids(jsonQuery),
    ...state.results.puids,
  };

  if (state.isQaMode) {
    puids.puid32 = 'test';
  }

  return puids;
}
