import { Spinner } from '@cian/ui-kit';

import { ReactNode, SyntheticEvent } from 'react';

import * as style from 'shared/serp/components/offers/index.css';

import * as styles from './SuggestionsLayout.css';

type ISuggestionsLayoutProps = {
  title: string;
  content: ReactNode;
  isLoading: boolean;
  hasMoreOffers: boolean;
  onShowMoreClick: (e: SyntheticEvent) => void;
};

export function SuggestionsLayout({
  title,
  content,
  isLoading,
  hasMoreOffers,
  onShowMoreClick,
}: ISuggestionsLayoutProps) {
  return (
    <div className={style['wrapper']}>
      <h3 className={style['title']}>{title}</h3>

      {content}

      {isLoading && (
        <div className={styles['preloader-container']}>
          <Spinner size={32} />
        </div>
      )}

      {!isLoading && hasMoreOffers ? (
        <div className={style['moreSuggestionsButtonContainer']}>
          <a className={styles['more-button']} href="#" onClick={onShowMoreClick}>
            Показать ещё
          </a>
        </div>
      ) : null}
    </div>
  );
}
