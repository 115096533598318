import {
  IconHighway16,
  IconMetroEkb16,
  IconMetroKzn16,
  IconMetroMsk16,
  IconMetroNN16,
  IconMetroNovosib16,
  IconMetroSam16,
  IconMetroSpb16,
  IconTrain16,
} from '@cian/ui-kit';
import { IIconProps } from '@cian/ui-kit/icons/internal';

import { CSSProperties, FC } from 'react';

import { TransportTypes } from '../../../../../../../../../types/TransportTypes';

import * as styles from './TransportIcon.css';

type UndergroundIconProps = {
  iconType: TransportTypes;
  iconColor: string | null;
};

export const TransportIcon: FC<UndergroundIconProps> = props => {
  const { iconType, iconColor } = props;

  const TransportIcon = ((): FC<IIconProps> => {
    switch (iconType) {
      case 'spb':
        return IconMetroSpb16;
      case 'ekb':
        return IconMetroEkb16;
      case 'kzn':
        return IconMetroKzn16;
      case 'nsk':
        return IconMetroNovosib16;
      case 'smr':
        return IconMetroSam16;
      case 'nn':
        return IconMetroNN16;
      case 'mow':
        return IconMetroMsk16;
      case 'highway':
        return IconHighway16;
      case 'railway':
        return IconTrain16;
    }
  })();

  const style: CSSProperties = {};

  if (iconColor) {
    style.color = iconColor;
  }

  return (
    <div className={styles['container']} style={style}>
      <TransportIcon color="current_color" data-testid={`icon-${iconType}`} />
    </div>
  );
};
