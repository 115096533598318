import * as R from 'ramda';
import { connect } from 'react-redux';

import { setLoading } from '../../../filters/state/search';
import { isRedesignExperimentEnabled } from '../../../selectors/isRedesignExperimentEnabled';
import { isUserProfilingModalVisible } from '../../../selectors/isUserProfilingModalVisible';
import { getUserEmail } from '../../../selectors/user/getUserEmail';
import { EHideOfferState, setHideOfferState } from '../../../serp/state/hide_offer';
import { setKeyPushNotificationValue } from '../../../serp/state/push_subscribed';
import { closeUserProfilingModal, openUserProfilingModal } from '../../../serp/state/user_profiling/toggle_modal';
import { isOfficeRentSearch } from '../../../utils/isOfficeRentSearch';
import { getTotalPagesCount } from '../../../utils/pagination';
import { getUserProfilingAvalablesEmails } from '../../../utils/userProfilingAvalablesEmails';
import { Dispatch, IAppState } from '../../state';

import { SearchEngineResultsPageProps } from './types';

import { SearchEngineResultsPage } from './index';

export type StateProps = Pick<
  SearchEngineResultsPageProps,
  | 'aggregatedOffers'
  | 'comparisonNotification'
  | 'config'
  | 'currency'
  | 'currentLocation'
  | 'currentUrl'
  | 'deviceType'
  | 'hideOfferErrorMessage'
  | 'hideOfferState'
  | 'httpApi'
  | 'isAdvancedModalVisible'
  | 'isCommercialIapRequestPopup'
  | 'isDirectionsModalVisible'
  | 'isDistrictModalVisible'
  | 'isFetching'
  | 'isLightView'
  | 'isLocationSwitcherVisible'
  | 'isMapModalVisible'
  | 'isMetroModalVisible'
  | 'isOfferInSavedSearch'
  | 'isOfficeCalculatorVisible'
  | 'isOfficeRentSearch'
  | 'isPrintEnabled'
  | 'isPromoCollectionsEnabled'
  | 'isPromotionMobileClosed'
  | 'isRedesignEnabled'
  | 'isStalkerBannerShown'
  | 'isTopPopupOpened'
  | 'isUserProfilingModalVisible'
  | 'jsonQuery'
  | 'lkUrl'
  | 'logger'
  | 'queryString'
  | 'quickLinks'
  | 'regions'
  | 'seo'
  | 'showPreInfinteSaveSearchBanner'
  | 'showUserProfilingModalOnceInSession'
  | 'subdomain'
  | 'tags'
  | 'user'
>;

type DispatchProps = Pick<
  SearchEngineResultsPageProps,
  | 'setKeyPushNotificationValue'
  | 'setHideOfferState'
  | 'setLoading'
  | 'onCloseUserProfilingModal'
  | 'onOpenUserProfilingModal'
>;
type OwnProps = Omit<SearchEngineResultsPageProps, keyof (DispatchProps & StateProps)>;

/* istanbul ignore next */
export function mapStateToProps(state: IAppState): StateProps {
  const { config, results } = state;

  const { aggregatedOffers, quickLinks, seo, jsonQuery } = results;

  /**
   * Нужно для эксперимента с микроразметкой
   * ---------------------------------------
   */
  const currencyVariant = {
    1: 'USD',
    2: 'RUB',
    3: 'EUR',
  };

  const currency =
    jsonQuery.currency && jsonQuery.currency.value
      ? currencyVariant[jsonQuery.currency.value]
      : /**
         * По умолчанию RUB
         */
        currencyVariant[2];

  //Эксперимент баннер перед бесконечной выдачей CD-44294
  const page = (jsonQuery.page && jsonQuery.page.value) || 1;
  const perPage = Number(config.get('serp.offersPerPage'));
  const totalPages = getTotalPagesCount(perPage, aggregatedOffers);
  const showPreInfinteSaveSearchBanner =
    Boolean(state.results.offers.length) && (totalPages === 1 || page === totalPages);
  const isOfferInSavedSearch = R.pathOr(undefined, ['results', 'jsonQuery', 'saved_search_id'], state);

  const userEmail = getUserEmail(state);
  const availableUsersEmails = getUserProfilingAvalablesEmails();
  const showUserProfilingModalOnceInSession = !availableUsersEmails.includes(userEmail);

  /* istanbul ignore next */
  return {
    currency,
    isPromotionMobileClosed: state.isPromotionMobileClosed,
    deviceType: state.deviceType,
    lkUrl: state.config.get<string>('hiddenObjects.lkUrl') || '',
    isPrintEnabled: state.print.enabled,
    isFetching: state.isFetching,
    aggregatedOffers,
    quickLinks,
    seo,
    subdomain: state.currentSubdomain,

    regions: state.filters.regions,
    currentLocation: state.filters.currentLocation,
    jsonQuery,
    /* istanbul ignore next */
    tags: state.filters.backup ? state.filters.backup.tags : state.filters.tags,

    user: state.user,
    isAdvancedModalVisible: state.isAdvancedModalVisible,
    isDirectionsModalVisible: state.filters.directionsModal.isVisible,
    isDistrictModalVisible: state.isDistrictModalVisible,
    isLocationSwitcherVisible: state.filters.locationSwitcher.isLocationSwitcherOpened,
    isMapModalVisible: state.isMapModalVisible,
    isStalkerBannerShown: state.isStalkerBannerShown,
    isMetroModalVisible: state.isMetroModalVisible,
    isOfficeCalculatorVisible: state.isOfficeCalculatorVisible,
    isTopPopupOpened: state.hideOffer.isTopPopupOpened,
    hideOfferState: state.hideOffer.hideOfferState,
    hideOfferErrorMessage: state.hideOffer.hideOfferErrorMessage,
    httpApi: state.httpApi,
    queryString: state.results.queryString,
    showPreInfinteSaveSearchBanner,
    currentUrl: state.filters.jsonQueryUrl,
    isLightView: false,
    isOfferInSavedSearch,
    isPromoCollectionsEnabled: state.isPromoCollectionsEnabled && page === 1,
    config: state.config,
    isRedesignEnabled: isRedesignExperimentEnabled(state),
    isOfficeRentSearch: isOfficeRentSearch(state.filters.jsonQuery),
    comparisonNotification: state.comparisonNotification,
    /**
     * @todo Удалить строку если эксперимент не заапрувится
     * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
     */
    isUserProfilingModalVisible: isUserProfilingModalVisible(state),
    /**
     * @todo Удалить строку если эксперимент не заапрувится
     * @description Данный функционал появился в задаче CD-133271, будет удалён в задаче CD-117944
     */
    showUserProfilingModalOnceInSession,
    isCommercialIapRequestPopup: !!state.config.get<boolean>('commercial.iapSerpPopup.Enabled'),
    logger: state.logger,
  };
}

export const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setKeyPushNotificationValue: (value: boolean) => dispatch(setKeyPushNotificationValue(value)),
  setHideOfferState: (hideOfferState: EHideOfferState) => dispatch(setHideOfferState(hideOfferState)),
  setLoading: () => dispatch(setLoading()),
  /**
   * @todo Удалить строку если эксперимент не заапрувится
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
   */
  onCloseUserProfilingModal: () => dispatch(closeUserProfilingModal()),
  /**
   * @todo Удалить строку если эксперимент не заапрувится
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
   */
  onOpenUserProfilingModal: () => {
    dispatch(openUserProfilingModal());
  },
});

export const SearchEngineResultsPageContainer = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchEngineResultsPage);
