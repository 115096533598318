import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { useOfferCardCreatorProps } from 'shared/hooks/useOfferCardCreatorProps';
import { IOffer } from 'shared/offer';
import { getOfferCardCreator, integrateAd } from 'shared/serp/components/offers/helpers';

type TRendererProps = {
  pageNumber: number;
  offers: IOffer[];
  offset?: number;
  keyPrefix?: string;
};

export function useSuggestionsOffersRenderer(): (props: TRendererProps) => React.ReactNode[] {
  const offerCardCreatorProps = useOfferCardCreatorProps();

  const suggestionsDistances = useSelector((state: IAppState) => state.suggestionsDistances);

  const getSuggestionsWithDistances = useCallback(
    (suggestions: IOffer[]): IOffer[] => {
      if (suggestionsDistances !== undefined && suggestions.length > 0) {
        return suggestions.map(suggest => {
          const distaces =
            suggestionsDistances?.data && suggestionsDistances?.data.find(distance => distance.offerId === suggest.id);

          return distaces ? { ...suggest, suggestionDistances: distaces.distance } : suggest;
        });
      }

      return suggestions;
    },
    [suggestionsDistances],
  );

  return useCallback(
    ({ pageNumber, offers, offset = 0, keyPrefix = 'suggestions' }) =>
      integrateAd({
        currentPageNumber: pageNumber,
        perPage: offerCardCreatorProps.offersPerPage,
        offers: getSuggestionsWithDistances(offers),
        offerCardCreator: getOfferCardCreator({
          props: offerCardCreatorProps,
          isSimilar: true,
          isSimilarFromML: true,
          pageNum: pageNumber,
        }),
        isPrintEnabled: offerCardCreatorProps.isPrintEnabled,
        // меняем знак, чтобы не рефачить integrateAd
        // в котором extendedOffersOffset вычитается из позиции расчитаной по номеру страницы
        // нужно чтобы корректно сдвинуть 1 страницу на сдвиг добивок
        extendedOffersOffset: -offset,
        hiddenOffers: offerCardCreatorProps.hideOffer.hiddenOffers,
        hiddenSuggestions: offerCardCreatorProps.hideOffer.hiddenSuggestions,
        mlRankingGuid: offerCardCreatorProps.mlRankingGuid,
        keyPrefix,
      }),
    [getSuggestionsWithDistances, offerCardCreatorProps],
  );
}
