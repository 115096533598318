import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { IOffer } from 'shared/packages/search-card-fragment/types/offer';
import { trackMLSuggestionsShown } from 'shared/serp/components/offers/tracking';
import { normalizeAbGroup } from 'shared/utils/normalizeAbGroup';

type TTrackingFunction = (
  suggestions: IOffer[] | null,
  pageNumber: number,
  allSuggestions?: IOffer[] | null,
  addedSuggestions?: IOffer[],
) => void;

export function useSuggestionsTracking(): TTrackingFunction {
  const jsonQuery = useSelector((state: IAppState) => state.results.jsonQuery);
  const breadcrumbs = useSelector((state: IAppState) => state.breadcrumbs);
  const queryString = useSelector((state: IAppState) => state.results.queryString);
  const offersQty = useSelector((state: IAppState) => state.results.totalOffers);
  const user = useSelector((state: IAppState) => state.user);
  const abGroup = useSelector((state: IAppState) =>
    normalizeAbGroup(state.userGADataLayerData && state.userGADataLayerData.abGroup),
  );

  return function handleTracking(suggestions, pageNumber, allSuggestions, addedSuggestions) {
    if (suggestions && suggestions.length > 0) {
      const offerWithModelVersion = (addedSuggestions || suggestions || []).find(
        offer => typeof offer.modelVersion !== 'object',
      );

      const modelVersion = offerWithModelVersion ? offerWithModelVersion.modelVersion : null;

      trackMLSuggestionsShown(
        jsonQuery,
        addedSuggestions || suggestions || [],
        allSuggestions || [],
        modelVersion,
        pageNumber,
        breadcrumbs,
        queryString,
        offersQty,
        user,
        abGroup,
      );
    }
  };
}
