import { IAppState } from '../common/state';
import { IJsonQuery } from '../repositories/common/json_query';

import { resultsSelector } from './commonSelectors';

export const getJsonQuery = ({ filters: { jsonQuery } }: IAppState) => {
  return jsonQuery;
};

export const jsonQueryFromResultsSelector = (state: IAppState): IJsonQuery => resultsSelector(state).jsonQuery;
