import { IAdFoxBannerOnErrorError, TAdFoxBannerProps } from '@cian/adfox-component';
import { withAdfoxPostViewAnalytics } from '@cian/postview-analytics-utils';
import { useBooleanState } from '@cian/react-utils';

import { clone } from 'ramda';
import { JSX, memo, useCallback, useMemo } from 'react';

import { AdfoxBanner } from './AdfoxBanner';
import { useAdfoxContext } from './external/context/AdfoxContext';

type AdFoxBannerContainerProps = {
  position?: number;
  onStub?(): void;
  onError?(error: IAdFoxBannerOnErrorError): void;
};

export const AdfoxBannerContainer = memo<AdFoxBannerContainerProps>(function AdFoxBanner(props) {
  const { onStub, onError, position } = props;

  const { params, size, postViewAnalyticsData } = useAdfoxContext();

  const { state: isHidden, setTrue: setHidden } = useBooleanState(false);

  const handleStub = useCallback(() => {
    setHidden();

    onStub?.();
  }, []);

  const handleError = useCallback((error: IAdFoxBannerOnErrorError) => {
    setHidden();

    onError?.(error);
  }, []);

  return useMemo((): JSX.Element | null => {
    if (isHidden) {
      return null;
    }

    const bannerProps: TAdFoxBannerProps = {
      onStub: handleStub,
      onError: handleError,
      params,
      lazyLoad: true,
      ...size,
    };

    let postViewAnalytics = postViewAnalyticsData;

    if (position != null) {
      postViewAnalytics = clone(postViewAnalytics);

      postViewAnalytics.banner.position = position;
    }

    const AdfoxWithPostView = withAdfoxPostViewAnalytics(AdfoxBanner, bannerProps, postViewAnalytics);

    return <AdfoxWithPostView />;
  }, [isHidden, handleStub, handleError, params, size, postViewAnalyticsData, position]);
});
