import { IJsonQuery } from '../../../../../../../../repositories/common/json_query';
import { TransportTypes } from '../../../../../../../../types/TransportTypes';

export function jsonRegionIdToTransportType(jsonQuery: IJsonQuery): TransportTypes | null {
  const regionId = jsonQuery.region?.value[0] ?? 1;

  switch (regionId) {
    case -2: // СПБиЛО
    case 2: // Ленинградская область
    case 4588: // Санкт-Петербург
      return 'spb';

    case -1: // МиМО
    case 1: // Московская область
    case 4593: // Москва
      return 'mow';

    case 4743: // Екатеринбург
      return 'ekb';

    case 4777: // Казань
      return 'kzn';

    case 4966: // Самара
      return 'smr';

    case 4897: // Новосибирск
      return 'nsk';

    case 4885: // Нижний Новгород
      return 'nn';

    default:
      return null;
  }
}
