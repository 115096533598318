import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { AdFoxTemplates, TTgbNativedescGeneralContext } from '@cian/adfox-templates';

import { Base64 } from 'js-base64';
import * as React from 'react';

import { useApplicationContext } from '../../../../../containers/ApplicationContext';
import { TgbNativedescGeneralContainer } from '../TgbNativedescGeneral';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES: Array<BannerContext['type']> = ['tgb_nativedesc_general'];

type BannerContext = TTgbNativedescGeneralContext;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const { bundleName, bundleParams } = props;

  const { logger } = useApplicationContext();

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'tgb_nativedesc_general':
                return <TgbNativedescGeneralContainer context={parsedDecodedParams} />;
            }
          }
        }
      } catch (e) {
        logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <AdFoxTemplates {...props} />;
};

function isParsedParamsValid(params: object): params is BannerContext {
  return 'type' in params;
}
