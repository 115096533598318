import { FC } from 'react';

import { AdfoxBannerContainer } from '../../../AdfoxBannerContainer';
import { AdfoxContextData, AdfoxContextProvider, PParams } from '../../context/AdfoxContext';

import style from './FooterBanner.css';

const POST_VIEW_ANALYTICS_DATA: AdfoxContextData['postViewAnalyticsData'] = {
  banner: {
    place: 'footerSerp',
    name: 'Listing_parallax',
  },
};
const P_PARAMS: PParams = { p1: 'dbtyp', p2: 'y' };
const SIZE: AdfoxContextData['size'] = { width: '100%', minHeight: '500px' };

export const FooterBanner: FC = () => {
  return (
    <div className={style['container']} data-name="FooterBanner">
      <AdfoxContextProvider pParams={P_PARAMS} postViewAnalyticsData={POST_VIEW_ANALYTICS_DATA} size={SIZE}>
        <AdfoxBannerContainer />
      </AdfoxContextProvider>
    </div>
  );
};
