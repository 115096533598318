/* eslint-disable max-lines */

import { ReactNode } from 'react';

import { IJsonQuery, IJsonQueryUnderground } from 'shared/json_query';
import { IBreadcrumb, IOffer } from 'shared/offer/types';
import { CommercialOfferCardContainer } from 'shared/packages/search-card-fragment/CommercialCard';
import { trackHiddenObjects } from 'shared/tracking/hide_offer';

import { EHideOfferState } from '../../state/hide_offer';
import { ParallaxBanner, TGBBanner } from '../AdfoxBanner';
import { CountrysideTrapBanner } from '../CountrysideTrapBanner';

import { prepareCommercialCardComponentsProps } from './commercial_card';
import {
  getTrackingParamsOfferPull,
  makeOfferAnalyticsClick,
  trackTradePopupClick as makeTradePopupClick,
  trackTradePopupShow as makeTradePopupShow,
  trackFavoriteAdded,
  trackFavoriteRemoved,
  trackPageJumped,
  trackPhoneOpened,
  trackPhotoSlideChanged,
} from './tracking';

import { TOffersProps } from './index';

export interface IIntegrateAdOptions {
  currentPageNumber: number;
  perPage: number;
  offers: IOffer[];
  offerCardCreator(offer: IOffer, position: number, keyPrefix: string): ReactNode;
  isPrintEnabled: boolean;
  hiddenOffers?: number[];
  hiddenSuggestions?: number[];
  animatingOffer?: number | null;
  positionOffset?: number;
  extendedOffersOffset?: number;
  mlRankingGuid: string | null;
  villagesLink?: string;
  showCountrysideTrap?: boolean;
  keyPrefix: string;
  trackShowNBMotivationPopup?: boolean;
  motivationPopupPosition?: number;
}

export const integrateAd = (options: IIntegrateAdOptions) => {
  const {
    currentPageNumber,
    perPage,
    offers,
    offerCardCreator,
    isPrintEnabled,
    hiddenOffers = [],
    hiddenSuggestions = [],
    villagesLink,
    showCountrysideTrap,
    extendedOffersOffset,
    keyPrefix,
  } = options;

  let positionOffset = options.positionOffset || 0;

  return offers.reduce((acc: ReactNode[], offer: IOffer, index) => {
    if (!hiddenOffers.includes(offer.id) && !hiddenSuggestions.includes(offer.id)) {
      const isOfferExtended = offer.extensionLabels && offer.extensionLabels.length;
      const position = (currentPageNumber - 1) * perPage + index;
      // порядок для события считаем начиная с единицы
      let trackingPosition = position + 1;

      const getBannerKey = (bannerName: string) => `${keyPrefix}_${bannerName}_${offer.id}`;

      // у добивок собственное начало отсчета
      trackingPosition = isOfferExtended ? trackingPosition - (extendedOffersOffset || 0) : trackingPosition;

      positionOffset += 1;

      acc.push(offerCardCreator(offer, trackingPosition, keyPrefix));

      if (!isPrintEnabled) {
        if (positionOffset === 5 || positionOffset === 15 || (positionOffset === 25 && offers.length > 25)) {
          acc.push(<TGBBanner key={getBannerKey('TgpBanner')} position={trackingPosition} />);
        } else if (positionOffset === 10 || positionOffset === 20) {
          acc.push(<ParallaxBanner key={getBannerKey('ParallaxBanner')} position={trackingPosition} />);
        }

        if (showCountrysideTrap && villagesLink && position === 1) {
          acc.push(<CountrysideTrapBanner key={`countryside_banner_${position}`} villagesLink={villagesLink} />);
        }
      }
    }

    return acc;
  }, []);
};

export const getOnFavoriteChange =
  (
    offer: IOffer,
    jsonQuery: IJsonQuery,
    position: number,
    onFavoriteChangedHandler: (offer: IOffer, isFavorite: boolean) => void,
  ) =>
  (isFavorite: boolean) => {
    const trackingLabel = offer.gaLabel;

    if (isFavorite) {
      trackFavoriteAdded(jsonQuery, { offerId: offer.id, position }, trackingLabel || undefined);
    } else {
      trackFavoriteRemoved(jsonQuery, { offerId: offer.id, position }, trackingLabel || undefined);
    }

    onFavoriteChangedHandler(offer, isFavorite);
  };

export interface IOfferCardCreatorProps extends TOffersProps {
  offersQty: number;
  queryString: string;
  priceRange?: string;
  defaultPhone?: string;
  defaultEmail?: string;
  showPushNotification?: boolean;
  breadcrumbs: IBreadcrumb[];
  isAgent?: boolean;
  soprApi: string;
  isRedesignEnabled: boolean;
}

interface IGetOfferCardCreator {
  props: IOfferCardCreatorProps;
  isSimilar?: boolean;
  isSimilarFromML?: boolean;
  pageNum?: number;
  isExtended?: boolean;
}

export const getOfferCardCreator =
  ({ props, isSimilar, isSimilarFromML, pageNum }: IGetOfferCardCreator) =>
  // eslint-disable-next-line react/display-name
  (offer: IOffer, position: number, keyPrefix: string): ReactNode => {
    const {
      baseUrl,
      breadcrumbs,
      currentPath,
      currentSubdomain,
      onFavoriteChanged,
      isBot,
      jsonQuery,
      onUserInfoPopupClose,
      onUserInfoPopupOpen,
      hideOfferAction,
      qsToUris,
      suggestionDistancesSeoText,
      user,
      userGADataLayerData,
      offersQty,
      queryString,
      hideOffer: { hideOfferState, hidingOffer },
      maxAuctionBet,
      maxAuctionService,
      onAnalyticsButtonClick,
      abUseExperiments,
      knAuctionCompanies,
    } = props;

    const { id } = offer;

    const onPopupClose = () => onUserInfoPopupClose(offer.id);
    const onPopupOpen = () => onUserInfoPopupOpen(offer.id);
    const onHideOffer =
      hideOfferState === EHideOfferState.FETCHING || hideOfferState === EHideOfferState.ANIMATING
        ? () => {}
        : () => {
            trackHiddenObjects({ action: 'Click_icon', offer, jsonQuery });
            hideOfferAction({ offer, isSimilar: !!isSimilar });
          };

    const onPageJumped = (label: string) =>
      trackPageJumped(offer, {
        user,
        userGALayerData: userGADataLayerData,
        jsonQuery,
        position,
        isSimilarFromML,
        label,
        pageNum,
        breadcrumbs,
        queryString,
        offersQty,
      });

    /* istanbul ignore next */
    const trackTradePopupShow = () => {
      makeTradePopupShow(offer, {
        user,
        userGALayerData: userGADataLayerData,
        jsonQuery,
        position,
        pageNum,
        queryString,
        offersQty,
      });
    };

    /* istanbul ignore next */
    const trackTradePopupClick = () =>
      makeTradePopupClick(offer, {
        user,
        userGALayerData: userGADataLayerData,
        jsonQuery,
        position,
        pageNum,
        queryString,
        offersQty,
      });

    const trackOfferAnalyticsClick = () =>
      makeOfferAnalyticsClick(offer, {
        user,
        userGALayerData: userGADataLayerData,
        jsonQuery,
        position,
        pageNum,
        queryString,
        offersQty,
      });

    const onButtonClick = () => {
      if (onAnalyticsButtonClick) {
        trackOfferAnalyticsClick();
        onAnalyticsButtonClick(
          offer.id,
          getTrackingParamsOfferPull(offer, {
            user,
            userGALayerData: userGADataLayerData,
            jsonQuery,
            position,
            pageNum,
            queryString,
            offersQty,
          }),
        );
      }
    };

    const commercialCardProps = prepareCommercialCardComponentsProps(
      props,
      {
        onPageJumped,
        onPopupClose,
        onPopupOpen,
        onFavoriteChange: getOnFavoriteChange(offer, jsonQuery, position, onFavoriteChanged),
        onHideOffer,
        onPhoneOpened: () =>
          trackPhoneOpened(offer, position, jsonQuery, user, userGADataLayerData, offersQty, queryString, isSimilar),
        onPhotoSlideChangedOnce: () => trackPhotoSlideChanged(offer.id, position),
        trackTradePopupShow,
        trackTradePopupClick,
        analyticsPopup: {
          onButtonClick,
        },
      },
      {
        offer,
        user,
        userGALayerData: userGADataLayerData,
        jsonQuery,
        position,
        pageNum,
        queryString,
        offersQty,
      },
    );

    const isOfficeSearch = !!offer.coworkingOfferType && !jsonQuery.coworking_offer_type;

    const isHideOfferFetching =
      Boolean(hidingOffer && hidingOffer.offer.id === offer.id) && hideOfferState === EHideOfferState.FETCHING;
    const isOnHideAnimation =
      Boolean(hidingOffer && hidingOffer.offer.id === offer.id) && hideOfferState === EHideOfferState.ANIMATING;

    return (
      <CommercialOfferCardContainer
        abUseExperiments={abUseExperiments}
        api={commercialCardProps}
        baseUrl={baseUrl}
        currentPath={currentPath}
        currentSubdomain={currentSubdomain}
        isBot={isBot}
        isHideOfferFetching={isHideOfferFetching}
        isOfficeSearch={isOfficeSearch}
        isOnHideAnimation={isOnHideAnimation}
        isSimilar={!!isSimilar}
        jsonQuery={jsonQuery}
        key={`${keyPrefix}-${id}`}
        knAuctionCompanies={knAuctionCompanies}
        maxAuctionBet={maxAuctionBet}
        maxAuctionService={maxAuctionService}
        offer={offer}
        position={position}
        qsToUris={qsToUris}
        suggestionDistancesSeoText={suggestionDistancesSeoText}
        user={user}
      />
    );
  };

export function getAdditionalAdFoxPuids(jsonQuery: IJsonQuery): { [key: string]: string } {
  const puid9 = (
    (jsonQuery.geo &&
      jsonQuery.geo.value
        .filter(geo => geo.type === 'underground')
        .map((underground: IJsonQueryUnderground) => underground.id)) ||
    []
  ).join(':');

  return {
    puid9,
  };
}

export function getRailwayAndHighwayPuids(jsonQuery: IJsonQuery): { [key: string]: string } {
  return jsonQuery.geo && jsonQuery.geo.value
    ? jsonQuery.geo.value.reduce(
        (acc, next) => {
          if (next.type === 'railway' && jsonQuery._type.includes('suburban')) {
            acc.puid34 = acc.puid34 ? `${acc.puid34}:${next.id}` : String(next.id);
          }

          if (next.type === 'highway' && jsonQuery._type.includes('suburban')) {
            acc.puid35 = acc.puid35 ? `${acc.puid35}:${next.id}` : String(next.id);
          }

          return acc;
        },
        { puid34: '', puid35: '' },
      )
    : {};
}

export function getOfferPrice(offer: IOffer) {
  let price = offer.minPriceTotalRur || offer.bargainTerms.priceRur;

  if (offer.bargainTerms.utilitiesTerms && !offer.bargainTerms.utilitiesTerms.includedInPrice) {
    price += offer.bargainTerms.utilitiesTerms.price;
  }

  return price;
}
