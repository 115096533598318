import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { TAgentRating } from 'shared/api/agent';
import { IQsToUris } from 'shared/api/offers';
import { Dispatch, IAppState } from 'shared/common/state';
import { changeFavorite } from 'shared/filters/state/favorite';
import { IOffer } from 'shared/offer';
import { isRedesignExperimentEnabled } from 'shared/selectors/isRedesignExperimentEnabled';
import { IOffersDispatchProps } from 'shared/serp/components/offers';
import { IOfferCardCreatorProps } from 'shared/serp/components/offers/helpers';
import { IHidingOfferInfo, hideOfferAction } from 'shared/serp/state/hide_offer';
import { hideMotivationPopup } from 'shared/serp/state/login_motivation_popup';
import { updateAgentRating } from 'shared/serp/state/offer_card/agent';
import { changeOfferComments } from 'shared/serp/state/offer_card/comments';
import { addOfferComplaint } from 'shared/serp/state/offer_card/complaints';
import {
  EFeedbackComplaint,
  IComplaintFeedbackBody,
  sendFeedbackComplaint,
  statusChanger,
} from 'shared/serp/state/offer_card/feedback_complaint';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from 'shared/serp/state/offer_card/simplified_card_popups';
import { addOfferToComparison } from 'shared/serp/state/offersComparison/actions/addOfferToComparison';
import { deleteOfferFromComparison } from 'shared/serp/state/offersComparison/actions/deleteOfferFromComparison';
import {
  ICoordinatesOffers,
  requestSuggestionsDistances,
  setQsToUris,
  setSuggestionDistancesSeoText,
} from 'shared/serp/state/suggestions';
import { prepareTrackingData } from 'shared/utils/prepareTrackingData';
import { isGoogleBot, isYandexBot } from 'shared/utils/user_agent';

export function useOfferCardCreatorProps(): IOfferCardCreatorProps {
  const dispatch: Dispatch = useDispatch();

  const stateProps = useSelector(selectOfferCardCreatorStateProps, shallowEqual);

  const dispatchProps = useMemo(() => {
    return {
      onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
        dispatch(changeFavorite(offer, isFavorite));
      },
      requestSuggestionsDistances: (coordinatesOffers?: ICoordinatesOffers[]) => {
        dispatch(requestSuggestionsDistances(coordinatesOffers));
      },
      closePopup: () => {
        dispatch(hideMotivationPopup());
      },
      onAgentRatingChanged: (offer: IOffer, rating: TAgentRating) => {
        dispatch(updateAgentRating(offer, rating));
      },
      onComplaintSent: (offerId: number, name: string, message?: string) => {
        dispatch(addOfferComplaint(offerId, name, message));
      },
      onOfferCommentsChanged: (offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined) => {
        dispatch(changeOfferComments(offer, commentOffer, commentAgent));
      },
      onPopupMoreOpen: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
      },
      onPopupReportOpen: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
      },
      onPopupMoreClose: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
      },
      onPopupReportClose: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
      },
      onUserInfoPopupOpen: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent'));
      },
      onUserInfoPopupClose: (offerId: number) => {
        dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent'));
      },
      setQsToUris: (qsToUris: IQsToUris) => {
        dispatch(setQsToUris(qsToUris));
      },
      setSuggestionDistancesSeoText: (text: string) => {
        dispatch(setSuggestionDistancesSeoText(text));
      },
      hideOfferAction: (hidingOffer: IHidingOfferInfo) => {
        dispatch(hideOfferAction(hidingOffer));
      },
      sendComplaintFeedback: (body: IComplaintFeedbackBody) => {
        dispatch(sendFeedbackComplaint(body));
      },
      statusChanger: (status: EFeedbackComplaint) => {
        dispatch(statusChanger(status));
      },
      addToComparison: ({ offerId }: { offerId: number }) => {
        dispatch(addOfferToComparison({ offerId }));
      },
      deleteFromComparison: ({ offerId }: { offerId: number }) => {
        dispatch(deleteOfferFromComparison({ offerId }));
      },
    };
  }, [dispatch]);

  return useMemo(
    () => ({
      ...stateProps,
      ...dispatchProps,
    }),
    [stateProps, dispatchProps],
  );
}

function selectOfferCardCreatorStateProps(state: IAppState): Omit<IOfferCardCreatorProps, keyof IOffersDispatchProps> {
  const {
    aggregatedOffers: aggregatedOffersCount,
    extendedOffersCount,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    jsonQuery,
    offers,
    queryString,
    qsToUris,
    ratingBlockedAgents,
    totalOffers,
  } = state.results;
  const { abUseExperiments } = state;

  const {
    currentPath,
    currentSubdomain,
    loginMotivationPopupOnFavorite,
    simplifiedOfferCardPopupsState,
    userAgent,
    config,
    hideOffer,
    complaintFeedbackFrom,
    maxAuctionBet,
    maxAuctionService,
    userGADataLayerData,
    deviceType,
    soprApi,
    excludedUtilitiesTermsRegions,
    knAuctionCompanies,
  } = state;

  /* istanbul ignore next */
  const isPopupsOpened = () => {
    const popupsStateKeys = Object.keys(simplifiedOfferCardPopupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(simplifiedOfferCardPopupsState[offerId].length));
  };

  return {
    abUseExperiments,
    aggregatedOffersCount,
    extendedOffersCount,
    offersQty: totalOffers,
    baseUrl: config.get('apiBaseUrl') || '',
    breadcrumbs: state.breadcrumbs,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    currentPageNumber: jsonQuery.page ? jsonQuery.page.value : 1,
    currentPath,
    currentSubdomain,
    isBot: isGoogleBot(userAgent) || isYandexBot(userAgent),
    isPrintEnabled: state.print.enabled,
    isPopupsOpened,
    jsonQuery,
    logger: state.logger,
    offers,
    offersPerPage: Number(config.get('serp.offersPerPage')),
    queryString,
    maxAuctionBet,
    maxAuctionService,
    qsToUris,
    ratingBlockedAgents,
    shownId: loginMotivationPopupOnFavorite.shownId,
    suggestionDistancesSeoText: state.suggestionDistancesSeoText,
    user: state.user,
    userGADataLayerData: state.userGADataLayerData,
    hideOffer,
    // лимит избранного для неавторизованного юзера
    favoritesLimitForUnauthUser: 5,
    mlRankingGuid: state.mlRankingGuid,
    complaintsFormStatus: complaintFeedbackFrom.status,
    soprApi,
    deviceType,
    excludedUtilitiesTermsRegions,
    isRedesignEnabled: isRedesignExperimentEnabled(state),
    trackingData: prepareTrackingData(state.filters, state.results, state.breadcrumbs, userGADataLayerData),
    knAuctionCompanies,
  };
}
